import {mapActions, mapGetters, mapMutations} from 'vuex'
import ImageFile from '@/components/image-file/index.vue'
import validationMixin from '@/mixins/validation'
import {mask} from 'vue-the-mask'
import {employerList, genderList, activityList} from '../../helpers'
import moment from "moment";

const {Entropy} = require('entropy-string')

export default {
    name: 'user-admins-create',
    mixins: [validationMixin],
    directives: {mask},
    data() {
        return {
            payload: {
                birthday: moment().subtract(20, 'year').toISOString().substr(0, 10),
                activity: '',
                gender: '',
                fullName: '',
                email: '',
                typeId: 0,
                originCountry: '',
                defaultCountry: '',
                isAuthor: false,
                isSpeaker: false,
                password: '',
                confirmPassword: '',
                employers: '',
                interface: '',
                isActive: false,
                sendToEmail: false,
                courses: [],
                file: '',
                accessCountry: '',
                isTester: false
            },
            showPassword: false,
            valid: false,
            openDatepicker: false,
            validationErrors: {}
        }
    },
    components: {
        ImageFile
    },
    computed: {
        ...mapGetters({
            profile: 'profile/profile',
            accessCountries: 'profile/accessCountries',
            countries: 'setting/countries',
            interfaceLanguages: 'setting/interfaceLanguages',
            coursesList: 'setting/coursesList',
            isLoading: 'userAdmins/isLoading',
            defaultCountries: 'profile/defaultCountries',
            userTypes: 'userSetting/userTypes'
        }),
        employerList,
        genderList,
        activityList,
        computedDateFormatted() {
            return this.formatDate(this.payload.birthday)
        },
        interfaceLanguagesNames() {
            if (!!this.interfaceLanguages && this.interfaceLanguages.length) return this.interfaceLanguages.map(item => ({
                ...item,
                name: item.locale
            }))
        }
    },
    created() {
        if (!this.countries) {
            this.fetchCountries()
        }
        if (!this.userTypes) {
            this.fetchUsersSetting()
        }
    },
    methods: {
        ...mapActions({
            fetchCountries: 'setting/GET_COUNTRIES_LIST',
            fetchInterface: 'setting/GET_LANGUAGE_INTERFACE_LIST',
            create: 'userAdmins/CREATE_USER',
            fetchUsersSetting: 'userSetting/GET_USERS_SETTING'
        }),
        ...mapMutations({}),
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        generateRandomPassword() {
            const entropy = new Entropy({
                total: 1e6,
                risk: 1e9
            })
            const string = entropy.string()
            this.payload.password = string
            this.payload.confirmPassword = string
            this.payload.sendToEmail = true
        },
        createRequestPayload() {
            const data = new FormData()
            data.append('full_name', this.payload.fullName)
            data.append('email', this.payload.email)
            data.append('phone', this.payload.phone)
            data.append('type_id', this.payload.typeId)
            data.append('origin_country_id', this.payload.originCountry)
            data.append('default_country_id', this.payload.defaultCountry)
            data.append('countries', this.payload.accessCountry)
            data.append('password', this.payload.password)
            data.append('password_confirmation', this.payload.confirmPassword)
            data.append('is_author', this.payload.isAuthor ? '1' : '0')
            data.append('is_speaker', this.payload.isSpeaker ? '1' : '0')
            data.append('active', this.payload.isActive ? '1' : '0')
            data.append('avatar', this.payload.file)
            data.append('gender', this.payload.gender)
            data.append('birthday', this.payload.birthday)
            data.append('interface_language', this.payload.interface)
            data.append('send_password', this.payload.sendToEmail ? 1 : 0)
            data.append('activity[type]', this.payload.activity)
            data.append('activity[employees_count]', this.payload.employers)
            data.append('is_tester', this.payload.isTester ? '1' : '0')
            return data
        },
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.create(this.createRequestPayload()).then(response => {
                    try {
                        this.$router.push({name: 'user.admins.personal-data', params: {id: response.id}})
                    } catch (e) {
                        console.log(e)
                    }
                    this.$toasted.success(this.$t('userSuccessCreate'))
                }).catch(error => {
                    const errors = error.response.data.validation
                    for (const i in errors) {
                        errors[i].forEach(e => {
                            this.$toasted.error(e)
                        })
                    }
                })
            }
        },
        changeDefaultCountriesValue() {
            this.fetchInterface({id: this.payload.defaultCountry})
        }
    }
}
